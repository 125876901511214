import React from "react";

import "../css/tailwind.css";
import { Link } from "react-router-dom";

const NotFound404 = () => {
  return (
    <div className='text-primary fullHeight flex flex-col justify-center'>
      <Link to="/" className="text-center">404 not found</Link>
    </div>
  );
};

export default NotFound404;
