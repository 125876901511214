import React from "react";

import "../css/tailwind.css";

const Capsule = () => {
  return (
    <div className='text-primary fullHeight flex flex-col justify-center'>
      <p className="text-center">In construction</p>
    </div>
  );
};

export default Capsule;
