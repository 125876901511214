import React from "react";

import "../css/tailwind.css";

import Navbar from "../components/navbar";
import AirbusSvg from "../assets/companies/airbus.svg";

const Airbus = () => {
  return (
    // <div className='text-primary fullHeight flex flex-col justify-center'>
    //   <p className="text-center">In construction</p>
    // </div>
    <div className="fullHeight">
      <div className="header bg-airbus min-h-[50vh] flex flex-col justify-center items-center">
        <Navbar className="flex-none" />
        <div className="flex flex-grow justify-center items-center">
          <img
            src={AirbusSvg}
            alt="airbus logo"
            className="animate-fade-in max-h-[50vh]"
          />
        </div>
      </div>

      {/* <div className=" text-background md:px-[5%]"> */}
      <div className=" text-background">
        <div className="section py-10">
          {/* Top infos */}
          <div className="flex flex-col justify-between m-auto md:flex-row md:gap-4">
            <div className="leading-loose">
              <h3 className="text-3xl font-bold">
                Research and development engineer
              </h3>
              <p>&gt; Dynaworks team</p>
              <p className="font-bold">&gt; #Qt #C++ #ProjectManagement #UI #DB</p>
            </div>

            <div className="leading-loose md:mt-0 mt-6">
              <h2 className="text-2xl font-bold">Airbus Defence & Space</h2>
              <p>&gt; Toulouse, France</p>
              <p>&gt; 3 years</p>
            </div>
          </div>
          {/* Content */}
          <div className="md:mt-10 m-auto mt-6">
            <p className="text-lg font-bold">About</p>
            <p>...
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor
              pretium viverra suspendisse potenti nullam ac tortor vitae. A
              pellentesque sit amet porttitor. Arcu ac tortor dignissim
              convallis aenean et. Venenatis tellus in metus vulputate eu
              scelerisque felis. Posuere ac ut consequat semper viverra.
              Ultrices neque ornare aenean euismod elementum nisi quis. Sed nisi
              lacus sed viverra tellus in hac habitasse platea. Posuere
              sollicitudin aliquam ultrices sagittis orci. Quis varius quam
            </p>
          </div>
        </div>
      </div>

      <style>{`body{background-color: #fdfdfd;}`}</style>
    </div>
  );
};

export default Airbus;
