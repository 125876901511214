import React from "react";
import { Link } from "react-router-dom";
import "../css/tailwind.css";


const ProjectCard = ({ project }) => {
  return (
    <div key={project.id} className="flex justify-center hover:opacity-70 transition-opacity duration-300 ease-in-out">
			<Link to={project.url} >
      <img
        src={require(`../assets/${project.image}`)}
        alt={project.name}
        className="aspect-video"
      />
      {/* <p className="text-lg font-semibold">{project.name}</p> */}
      </Link>
    </div>
  );
};

export default ProjectCard;
