import React from "react";

import profil from "../assets/profil.png";

import "../css/tailwind.css";
import Navbar from "../components/navbar";

const About = () => {
  return (
    <div className="bigsection fullHeight">
      <Navbar />
      <div className="text-primary flex flex-col md:flex-row justify-between items-center max-w-[950px] m-auto pt-10">
        <h3 className="text-4xl md:hidden my-4">Hey, i'm Romain</h3>
        <div className="basis-2/5 mb-2 sm:mb-0">
          <img
            src={profil}
            alt="profile"
            className="rounded-full w-48 md:w-64"
          />
        </div>
        <div className="basis-3/5 text-justify">
          <h3 className="text-4xl hidden md:block my-4">Hey, i'm Romain</h3>
          <p className="mb-2">
            I'm currently exploring the world of{" "}
            <span className="font-bold text-secondary">freelancing</span>,
            focusing on software engineering and digital solution development.
          </p>
          <p className="mb-2">
            Previously, I worked as an IT Consultant at the startup rNFT,
            delving into web3 technologies. Prior to that, I worked as a
            Research and Development Engineer at Airbus Defence and Space -
            Intelligence in Toulouse, France, contributing to the development of
            3D visualization solutions and cartographic solutions. I also had a
            remarkable journey incubating a startup in the dating and social app
            sector, which provided invaluable experiences in the dynamic world
            of tech startups.
          </p>
          <p className="mb-2">
            I hold an Engineering degree in Computer Science from INSA Lyon and
            a specialized Master in Entrepreneurship & Innovation Management
            from Emlyon Business School. My passion for technology and
            entrepreneurship was ignited during my university years, leading me
            to the path of software engineering and business innovation.
          </p>
          <p className="mb-2">
            Today, I am engaged in applying my diverse skills in{" "}
            <span className="font-bold text-secondary">
              project management, mobile development, and solution architecture
            </span>{" "}
            to various challenging projects.
          </p>
          <p className="mb-2">
            Feel free to reach out to me{" "}
            <a
              href="#"
              onClick={(e) => {
                window.location.href = "mailto:romain.chikirou@email.com";
              }}
              className="hover:underline hover:text-sky-600 text-secondary"
            >
              here
            </a>{" "}
            or connect on{" "}
            <a
              href="https://www.linkedin.com/in/romain-chikirou"
              className="hover:underline hover:text-sky-600 text-secondary"
            >
              LinkedIn
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
