import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo.png";
import resume from "../assets/resume.pdf";

import "../css/tailwind.css";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="text-white p-4 flex justify-between items-center h-32">
      <div className="flex">
        <Link to="/">
          <img src={logo} alt="Logo" width="100" height="auto" />
        </Link>
      </div>
      <nav>
        {/* DESKTOP-MENU  */}
        <ul className="DESKTOP-MENU hidden space-x-8 md:flex text-lg font-semibold">
          <li className="px-4">
            <Link to="/about" className="linkAnimation">
              About
            </Link>
          </li>
          <li className="px-4">
            <a
              href={resume}
              target="_blank"
              rel="noopener noreferrer"
              className="linkAnimation"
            >
              Resume
            </a>
          </li>
        </ul>

        {/* MOBILE-MENU  */}
        <section className="flex md:hidden">
          {/* HAMBURGER-ICON */}
          <div
            className="space-y-2 cursor-pointer"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-400"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-400"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-400"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-4 right-4 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-400 cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase">
                <Link to="/" className="linkAnimation" onClick={()  => setIsNavOpen(false)}>
                  Work
                </Link>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <Link to="/about" className="linkAnimation" onClick={()  => setIsNavOpen(false)}>
                  About
                </Link>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a
                  href={resume}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkAnimation" 
                  onClick={()  => setIsNavOpen(false)}
                >
                  Resume
                </a>
              </li>
            </ul>
          </div>
        </section>
      </nav>
    </div>
  );
};

export default Navbar;
