import React from "react";

import ProjectCard from "../components/projectCard";
import InfinitSlider from "../components/infiniteSlider";

import Navbar from "../components/navbar";

import "../css/tailwind.css";

const Home = () => {
  const projects = [
    {
      name: "Capsule",
      image: "previews/capsule_preview.jpg",
      url: "/capsule",
    },
    {
      name: "Bliss Dating Game",
      image: "previews/bliss_preview.jpg",
      url: "/bliss",
    },
    {
      name: "RNFT",
      image: "previews/rnft_preview.jpg",
      url: "/rnft",
    },
    {
      name: "Airbus",
      image: "previews/airbus_preview.jpg",
      url: "/airbus",
    },
    {
      name: "Rolex",
      image: "previews/rolex_preview.jpg",
      url: "/rolex",
    },
    {
      name: "Education",
      image: "previews/education_preview.jpg",
      url: "/education",
    },
  ];

  return (
    <div className="text-primary bigsection">
      <Navbar />

      {/* Header */}
      <div className="text-xl sm:pt-20 md:pt-32">
        <p>Hey, my name is</p>
        {/* <img src={name} alt="Logo" className="h-20" /> */}
        <h1 className="text-6xl py-2 font-bold">Romain CHIKIROU</h1>
        <div className="max-w-md">
          <p>I'm an IT full stack engineer in freelance.</p>
          <p>Check my work and contact me.</p>
        </div>
      </div>

      <div className="my-16">
        {" "}
        <InfinitSlider />
      </div>

      {/* Projects  */}
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>

    </div>
  );
};

export default Home;
