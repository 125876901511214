import React from "react";

import Navbar from "../components/navbar";
import "../css/tailwind.css";

import RolexSvg from "../assets/companies/rolex.svg";

const Rolex = () => {
  return (
    <div className="fullHeight">
      <div className="header bg-rolex min-h-[50vh] flex flex-col justify-center items-center">
        <Navbar className="flex-none" />
        <div className="flex flex-grow justify-center">
          <img src={RolexSvg} alt="rolex logo" className="animate-fade-in" />
        </div>
      </div>

      <div className="bg-[#fdfdfd] text-background md:px-[5%]">
        <div className="section py-10">
          {/* Top infos */}
          <div className="flex flex-col justify-between m-auto md:flex-row">
            <div className="leading-loose">
              <h3 className="text-2xl font-bold">Software developer intern</h3>
              <p>&gt; Industrialisation & automatisation team</p>
              <p className="font-bold">&gt; #UML #VBA #SQL</p>
            </div>

            <div className="leading-loose md:mt-0 mt-6">
              <h2 className="text-2xl font-bold">Rolex SA</h2>
              <p>&gt; Genève, Switzerland</p>
              <p>&gt; 4 months</p>
            </div>
          </div>
          {/* Content */}
          <div className="md:mt-10 m-auto mt-6">
            <p className="text-lg font-bold">About</p>
            <p>
              While I can't reveal all details due to confidentiality, I can
              share that my responsibilities covered every aspect, from
              specification development to implementation. This included
              creating <strong>UML diagrams</strong>, designing the solution architecture, and
              utilizing <strong>Excel</strong> and <strong>SQL</strong>.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#fdfdfd] text-background md:px-[5%]">
        <div className="section py-10">
          <h4 className="text-center text-2xl mb-4">What i've learned</h4>
          <p>
            My internship at Rolex provided me with a deep understanding of IT
            projects in a corporate setting, emphasizing the crucial role of
            communication in project success. The frequent presentations honed
            my <strong>communication skills</strong>, underscoring the need to simplify technical
            terms. The <strong>autonomy</strong> granted, coupled with regular oversight,
            strengthened my sense of responsibility and fueled my motivation.
            While my initial goal was software development, this experience
            balanced my skills between technical aspects and communication.
          </p>
        </div>
      </div>

      <style>{`body{background-color: #fdfdfd;}`}</style>
    </div>
  );
};

export default Rolex;
