import React from "react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Home from "./pages/home";
import About from "./pages/about";
import Footer from "./components/footer";
import NotFound404 from "./pages/notFound404";
import Rolex from "./pages/rolex";
import Airbus from "./pages/airbus";
import Rnft from "./pages/rnft";
import Capsule from "./pages/capsule";
import Bliss from "./pages/bliss";
import Education from "./pages/education";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <div className="app">
      <Router>
      <ScrollToTop />
      {/* <Navbar /> */}
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/about" exact element={<About/>} />
          <Route path="/capsule" exact element={<Capsule/>} />
          <Route path="/bliss" exact element={<Bliss/>} />
          <Route path="/rnft" exact element={<Rnft/>} />
          <Route path="/airbus" exact element={<Airbus/>} />
          <Route path="/rolex" exact element={<Rolex/>} />
          <Route path="/education" exact element={<Education/>} />
          <Route path="/*" exact element={<NotFound404/>} />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
