import React from "react";

import "../css/tailwind.css";

import Navbar from "../components/navbar";

const Education = () => {
  return (
    // <div className='text-primary fullHeight flex flex-col justify-center'>
    //   <p className="text-center">In construction</p>
    // </div>
    <div className="fullHeight">
      <div className="header bg-insa min-h-[50vh] flex flex-col justify-center items-center">
        <Navbar className="flex-none" />
        <div className="flex flex-grow justify-center">
          <h4 className="text-center text-6xl m-auto text-primary font-bold animate-fade-in">
            Education
          </h4>
        </div>
      </div>

      {/* <div className=" text-background md:px-[5%]"> */}
      <div className=" text-background md:px-[5%]">
        <div className="section py-10">
          {/* INSA */}
          <div className="flex flex-col justify-between m-auto md:flex-row md:gap-4">
            <div className="leading-loose basis-5/12">
              <h3 className="text-2xl font-bold">Computer engineering</h3>
              <p className="text-lg">&gt; INSA Lyon</p>
              <p>&gt; France</p>
            </div>

            <div className="leading-loose md:mt-0 mt-6 basis-7/12">
              <h2 className="text-lg font-bold">About</h2>
              <p>
                I pursued my IT Engineering degree at INSA Lyon, with a valuable
                international exchange semester in Budapest. My coursework
                covered essential areas such as Project Management, Algorithms,
                System Architecture, Databases, and Networking. The academic
                experience extended to collaborative projects in real-world
                scenarios, equipping me with a robust foundation for tackling
                complex challenges in the field of IT.
              </p>
            </div>
          </div>
        </div>

        {/* EM  */}
        <div className="section py-10">
          {/* EM */}
          <div className="flex flex-col justify-between m-auto md:flex-row md:gap-4">
            <div className="leading-loose basis-5/12">
              <h3 className="text-2xl font-bold">
                Masters in entrepreneurship and innovation
              </h3>
              <p className="text-lg">&gt; EM Lyon Business Scool</p>
              <p>&gt; France</p>
            </div>

            <div className="leading-loose md:mt-0 mt-6 basis-7/12">
              <h2 className="text-lg font-bold">About</h2>
              <p>
                Master's in Entrepreneurship at emlyon transformed my technical
                background into practical entrepreneurial skills. The curriculum
                covered strategy, innovation, and financial planning. Through
                project-centric learning, I collaborated on impactful ventures
                such as TEM22's Med For Me, winning the investors' prize for
                reshaping patient involvement in ambulatory surgery. This
                experience enhanced my project management, market study,
                marketing skills, and communication abilities. Emlyon has been a
                fulfilling journey, enhancing both professional and personal
                growth, laying a robust foundation for my entrepreneurial
                aspirations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" text-background md:px-[5%]">
        <div className="section py-10">
          <h4 className="text-center text-2xl mb-4 font-bold">
            Why this career path ?
          </h4>
          <p className="mb-2 text-justify">
            I've always been hooked on IT and technology, which led me to pursue
            a degree in Computer Science. It was more than just a field of study
            for me; it was about feeding my curiosity and understanding the
            gears behind our digital world.
          </p>
          <p className="mb-2 text-justify">
            But as I dove into my studies, I found myself intrigued by the
            business side of things. The idea of one day starting my own tech
            business started to take root. This curiosity steered me towards a
            Master’s in Entrepreneurship and Innovation.
          </p>
          <p className="mb-2 text-justify">
            Now, I'm not just passionate about technology, but also about the
            startup ecosystem. I'm excited to blend my tech know-how with
            entrepreneurial thinking, aiming to make a mark in the tech startup
            world.
          </p>
        </div>
      </div>

      <style>{`body{background-color: #fdfdfd;}`}</style>
    </div>
  );
};

export default Education;
