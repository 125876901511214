import React from "react";

import "../css/tailwind.css";

const Footer = () => {
  return (
    <footer className="py-10 text-gray-300 flex justify-center mix-blend-difference">
      <a href="#" onClick={(e) => {window.location.href ='mailto:romain.chikirou@email.com';}}
        className="font-semibold"
        target="_blank"
        rel="noreferrer"
      >
        Mail
      </a>
      <span className="px-1 font-semibold">.</span>
      <a
        href="https://github.com/romainchkr"
        className="font-semibold"
        target="_blank"
        rel="noreferrer"
      >
        Github
      </a>
      <span className="px-1 font-semibold">.</span>
      <a
        href="https://www.linkedin.com/in/romain-chikirou"
        className="font-semibold"
        target="_blank"
        rel="noreferrer"
      >
        LinkedIn
      </a>
      
      
    </footer>
  );
};

export default Footer;
