import React from "react";
import "../css/tailwind.css";

import Flutter from "../assets/technos/flutter.svg";
import ReactLogo from "../assets/technos/react.svg";
import NodeJs from "../assets/technos/nodedotjs.svg";
import AmazonAWS from "../assets/technos/amazonaws.svg";
import Firebase from "../assets/technos/firebase.svg";
import Python from "../assets/technos/python.svg";
import MongoDB from "../assets/technos/mongodb.svg";
import PostgreSQL from "../assets/technos/postgresql.svg";
import Qt from "../assets/technos/qt.svg";

const Technos = () => {
  const technos = [
    { logo: Flutter, name: "Flutter", url: "https://flutter.dev/" },
    { logo: ReactLogo, name: "React", url: "https://react.dev/" },
    { logo: NodeJs, name: "Node.js", url: "https://nodejs.org/en/" },
    { logo: MongoDB, name: "MongoDB", url: "https://www.mongodb.com/" },
    { logo: PostgreSQL, name: "PostgreSQL", url: "https://www.postgresql.org/" },
    { logo: AmazonAWS, name: "Amazon AWS", url: "https://aws.amazon.com/" },
    { logo: Firebase, name: "Firebase", url: "https://firebase.google.com/" },
    { logo: Python, name: "Python", url: "https://www.python.org/" },
    { logo: Qt, name: "Qt", url: "https://www.qt.io/" },
  ];

  return (
    <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
      {/* Logos */}
      <ul className="flex items-center justify-center md:justify-start [&_li]:mx-10 [&_img]:max-w-none animate-infinite-scroll">
        {technos.map((techno, index) => (
          <li key={index}>
            <a href={techno.url} target="_blank">
              <img
                src={techno.logo}
                alt={techno.name}
                title={techno.name}
                className="h-12 w-auto"
              />
            </a>
          </li>
        ))}
      </ul>
      <ul
        className="flex items-center justify-center md:justify-start [&_li]:mx-10 [&_img]:max-w-none animate-infinite-scroll"
        aria-hidden="true"
      >
        {technos.map((techno, index) => (
          <li key={index}>
            <a href={techno.url} target="_blank">
              <img
                src={techno.logo}
                alt={techno.name}
                title={techno.name}
                className="h-12 w-auto"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Technos;
